import React from "react"

import { LocaleProvider } from "../../context/Locale"

function Layout({ children, data, location, pageContext: { locale } }) {
  return (
    <React.Fragment>
      <LocaleProvider
        locales={data?.strapi?.i18NLocales?.data}
        locale={locale}
        location={location}
      >
        {children}
      </LocaleProvider>
    </React.Fragment>
  )
}

export default Layout
