import React from "react"
import wrapWithProvider from "./wrap-with-provider"

import "./src/assets/js/custom.js"
import "jquery/dist/jquery.min.js"
import "jquery-slim/dist/jquery.slim.js"

import Layout from "./src/components/layouts/index"
import { CurrencyProvider } from "./src/context/Currency"

import "./src/styles/main.css"

export const wrapRootElement = wrapWithProvider

export const wrapPageElement = ({ element, props }) => {
  return (
    <CurrencyProvider>
      <Layout {...props}>{element}</Layout>
    </CurrencyProvider>
  )
}
