import React from "react"

import { GraphQLClient, ClientContext } from "graphql-hooks"

const client = new GraphQLClient({
  url: "https://ahcms.rentalls.com/graphql",
})

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  return (
    <ClientContext.Provider value={client}>{element}</ClientContext.Provider>
  )
}
