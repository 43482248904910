import React from "react";

export const CurrencyContext = React.createContext({
  currency: "USD",
  setCurrency: (code) => {}
});

export function CurrencyProvider({ children }) {
  const [currency, setCurrency] = React.useState("EUR");
  return (
    <CurrencyContext.Provider value={{ currency, setCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
}
